import { Box, Typography } from "@mui/material";
import { useState } from "react";

export default function ListingWidget({ image, title, summary, tags, isMobile }) {
  const [isShown, setIsShown] = useState(true);
  const centerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  };

  const imageStyle = {
    width: "100%",
    objectFit: "cover",
    aspectRatio: isMobile ? "4/3" : "3/2",
  };

  const handleMouseOver = () => {
    if (!isMobile) setIsShown(false);
  };
  const handleMouseOut = () => {
    if (!isMobile) setIsShown(true);
  };

  return (
    <Box>
      <Box
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        sx={{
          borderRadius: "8px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={image}
          style={{ ...imageStyle, transform: isShown ? null : "scale(1.1)" }}
          className={isShown ? null : "zoom"}
          alt={title}
        />
        <div className="tag_background">
          <div className="tag_section">
            {tags &&
              tags.map((t, index) => {
                return (
                  <div key={index} className="tag_label">
                    {t}
                  </div>
                );
              })}
          </div>
        </div>
        <div
          className={"overlay" + (isShown ? "" : " fade")}
          style={{ ...centerStyle, padding: "10px", opacity: isShown ? 0 : 0.9 }}
        >
          <div className="overlay_content">
          <div className="overlay_textcontent">
            <Typography variant="h6" sx={{ color: "white", fontWeight: "700" }}>
              {title}
            </Typography>
            <Typography variant="body1" sx={{ color: "white", fontWeight: "100" }}>
              {summary}
            </Typography>
          </div>
          <div>
              {tags &&
                tags.map((t, index) => {
                  return (
                    <div key={index} className="tag_label">
                      {t}
                    </div>
                  );
                })}
          </div>
          </div>
        </div>
      </Box>
      {isMobile && <div className="overlay_textcontent">
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "100" }}>
            {summary}
          </Typography>
      </div>}
    </Box>
  );
}
