// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PortSection = {
  "DEV": "DEV",
  "UX": "UX",
  "DESIGN": "DESIGN"
};

const { PorfolioContent, Todo, PortLink } = initSchema(schema);

export {
  PorfolioContent,
  Todo,
  PortSection,
  PortLink
};