import { Box, Grid, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function OpenWidget({
  openImages,
  openTitle,
  openTags,
  openDescription,
  open,
  handleClose,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90%",
    minWidth: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "16px",
    scrollbarGutter: "stable",
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: "25px",
            minWidth: "60vw",
          }}
        >
          <CloseIcon className="hover_interactable popup_close" title="Close" onClick={handleClose}></CloseIcon>
          <p id="modal-modal-title" variant="h5" className="font-header">
            {openTitle}
          </p>
          {openTags &&
            openTags.map((t, index) => {
              return (
                <div key={index} className="tag_label" style={{ margin: "0 10px 0 0", backgroundColor: "lightgray" }}>
                  {t}
                </div>
              );
            })}
          <Typography id="modal-modal-description" className="description" sx={{ mt: 2 }}>
            <span dangerouslySetInnerHTML={{ __html: openDescription }}></span>
          </Typography>
        </Box>

        <Box
          sx={{
            padding: "25px",
            minWidth: "60vw",
          }}
        >
            <Grid container spacing={2} >
              {openImages.map((image, index) => {
                let size = (openImages.length === 3) ? 4 : ((openImages.length > 1) ? 6 : 12);
                return (
                  <Grid item xs={12} sm={size} key={index}>
                    <img
                      src={image}
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        backgroundColor: "#eee"
                      }}
                      alt={openTitle}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
