import { NavLink } from "react-router-dom";
import PageContainer from "../components/PageContainer";

export default function NoMatch() {
  return (
      <PageContainer>
        <p className="font-header">
            This page does not exist.
        </p> 
        <p>
            Please go back to the <NavLink to="/">main page.</NavLink>
        </p>
      </PageContainer>
  );
}
