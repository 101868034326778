import { Grid } from "@mui/material";
import { useState } from "react";
import ListingWidget from "../components/ListingWidget";
import OpenWidget from "../components/OpenWidget";
import PageContainer from "../components/PageContainer";
import { assetList } from "../static/assetList";
import { useOutletContext } from "react-router-dom";

export default function FilteredGrid({ filterSection }) {
  const {isMobile} = useOutletContext();
  const [open, setOpen] = useState(false);
  const [openImages, setImages] = useState(['']);
  const [openTags, setTags] = useState(['']);
  const [openTitle, setTitle] = useState();
  const [openDescription, setDescription] = useState();

  const handleOpen = (id) => {
    setImages([]);
    assetList[id].image.forEach( img => {
      const imagePath = require(`../static${img}`);
      setImages((openImages) => [...openImages, imagePath]);
    })
    setTags([]);
    if (assetList[id].tags) {
      assetList[id].tags.forEach( tag => {
        setTags((openTags) => [...openTags, tag]);
      })
    }
    
    setTitle(assetList[id].title);
    setDescription(assetList[id].desc);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <PageContainer>
      <Grid container spacing={5}>
        {assetList.map(({ section, image, title, summary, tags }, index) => {
          const mainImg = require(`../static${image[0]}`);
          const sections = section.split("|");
          if (sections.some(s => s === filterSection ) || filterSection === "") {
            return (
              <Grid
                item
                xs={12}
                md={6}
                key={index}
                onClick={() => handleOpen(index)}
              >
                <ListingWidget
                  image={mainImg}
                  title={title}
                  summary={summary}
                  tags={tags}
                  isMobile={isMobile}
                ></ListingWidget>
              </Grid>
            );
          } else return <span key={index}></span>;
        })}
      </Grid>

      {open && <OpenWidget
        openImages={openImages}
        openTitle={openTitle}
        openTags={openTags}
        openDescription={openDescription}
        open={open}
        handleClose={handleClose}
      />}
    </PageContainer>
  );
}
