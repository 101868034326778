import { useState } from "react";
import { Box, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";

import image1 from "../static/images/About/about1.png";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";

export default function Home() {
  const [displayName, setDisplayName] = useState('Johannine');

  const imageStyle = {
    padding: "30px",
    width: "100%",
    objectFit: "cover"
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  const animateTo = async () => {
    setDisplayName('Jo - han - 9 ');
  };
  const animateFrom = async () => {
    await delay(1000);
    setDisplayName('Johannine');
  };

  return (
    <Box sx={{
      minWidth: "70vw",
      maxWidth: "85vw",
      height: "80vh",
      pt: "85px",
      mx: "auto",
    }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "justify",
        }}
      >
        <Grid item xs={12} sm={6} md={4} className="center">
          <img src={image1} alt="Johannine" style={imageStyle}></img>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <p className="font-header">Hi! I'm <span onMouseEnter={animateTo} onMouseLeave={animateFrom}>{displayName}</span></p>
          <p className="description">
            I am a final-year Computer Engineering student from the National University of Singapore. I love to code and design, and I aspire to create beautiful interactive applications that can make people's lives easier or more enriched.
          </p>
          <p className="description">
            Showcased on this <NavLink className="hover-a" to="/work/">portfolio</NavLink> are some of the projects that I have worked on thus far. It's quite an assortment of things right now, from academic projects to hackathon creations, from personal explorations to creative pursuits. This site is currently a work in progress, as am I, but it's a learning process that I'm excited to embark on!
          </p>
          <p className="description">
            If you'd like to reach me or discuss more about my qualifications, feel
            free to connect with me through LinkedIn or email.
          </p>
          <br></br>
          <Box sx={{
            mb: "40px",
          }}>
          <Grid container spacing={3}>
            <Grid item>
              <a className="hover-a" title="LinkedIn" href="https://www.linkedin.com/in/johannine-enerio/">
                <LinkedInIcon />
              </a>
            </Grid>
            <Grid item>
              <a className="hover-a" title="Copy my email" href="mailto:johannine.enerio@gmail.com">
                <MailIcon />
              </a>
            </Grid>
            <Grid item className="hover-a">
              <a className="hover-a" title="GitHub" href="https://github.com/johan9se">
                <GitHubIcon />
              </a>
            </Grid>
          </Grid>
          </Box>

        </Grid>
    </Grid>
    </Box>
  );
}
