import { useRoutes } from "react-router-dom";
import Base from "./layout/Base";
import FilteredGrid from "./components/FilteredGrid";
import Contact from "./pages/contact";
import NoMatch from "./pages/nomatch";
import Home from "./pages/home";
import Form from "./pages/form";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Base />,
      children: [
        {
          path: "/",
          element: <Home />
        },
        {
          path: "/contact",
          element: <Contact />
        },
        {
          path: "/amplifyform",
          element: <Form />
        },
        {
          path: "/work/",
          element: <FilteredGrid filterSection=""></FilteredGrid>,
        },
        {
          path: "/work/ux",
          element: <FilteredGrid filterSection="UX"></FilteredGrid>,
        },
        {
          path: "/work/dev",
          element: <FilteredGrid filterSection="Dev"></FilteredGrid>,
        },
        {
          path: "/work/design",
          element: <FilteredGrid filterSection="Design"></FilteredGrid>,
        },
      ],
    },
    {
      path: "*",
      element: <NoMatch />,
    }
  ]);
}
