import { useState } from "react";
import { Box } from "@mui/material";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Base() {
  const { pathname } = useLocation();
  const [navTypeIsBasic, setNavType] = useState(true);

  const styles = {
    navbar : {
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "white",
      position: "fixed",
      top: "0",
      zIndex: "2",
    },
    nav : {
      minWidth: "70vw",
      padding: "25px 0",
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if(pathname.includes("/work")) setNavType(false);
    else setNavType(true);
  }, [pathname]);

  return (
    <>
    <Box sx={styles.navbar}>
      {/* <Box  sx={styles.nav} className={navTypeIsBasic ? "slideInRight" : "slideOutRight"}> */}
      {/* <Box  sx={styles.nav} className={navTypeIsBasic ? "slideOutLeft" : "slideInLeft"}> */}
      <Box  sx={styles.nav}>
      { navTypeIsBasic ? 
          <Nav activeKey={pathname} fill>
            <Nav.Item>
              <NavLink to="/">Home</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/work/">Work</NavLink>
            </Nav.Item>
            <Nav.Item>
              <a
                style={{ textDecoration: "none" }}
                href="https://jo-portfolio.s3.ap-southeast-1.amazonaws.com/Resume_JohannineEnerio.pdf"
                target="_blank" rel="noreferrer"
              >
                Resume
              </a>
            </Nav.Item>
            <Nav.Item>
              <a className="hover-a footer-icon" title="LinkedIn" href="https://www.linkedin.com/in/johannine-enerio/">
                Connect
              </a>
            </Nav.Item>
          </Nav>
          :
          <Nav activeKey={pathname} fill>
            <Nav.Item>
              <NavLink to="/"><ArrowBackIcon /></NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/work/">All</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/work/dev">Development</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/work/ux">UX</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/work/design">Design</NavLink>
            </Nav.Item>
          </Nav>
          }
        </Box>
      </Box>
      <Outlet context={{isMobile}}/>
    </>
  );
}
