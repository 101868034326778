import { Box } from "@mui/material";
import PageContainer from "../components/PageContainer";

export default function Contact() {
  return (
      <PageContainer>
        <Box>
            hi 
        </Box>
      </PageContainer>
  );
}
