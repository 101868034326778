import { Box, Typography } from "@mui/material";
import { Container } from "react-bootstrap";

export default function SectionContainer({ title, desc, children }) {
  return (
    <>
      <Box sx={{ my: "30px" }}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">
          {desc && desc.map((text, i) => <span key={i}>{text}</span>)}
        </Typography>
      </Box>
      <Container style={{ paddingBottom: "100px" }}>{children}</Container>
    </>
  );
}
