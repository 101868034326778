import Footer from "./Footer";
import SectionContainer from "./SectionContainer";

export default function PageContainer({ title, desc, children, withFooter=true }) {
  return (
    <>
      <div style={{ minHeight: "100vh", padding: "85px 0", textAlign: "center" }}>
        <SectionContainer title={title} desc={desc}>
          {children}
        </SectionContainer>
        {withFooter && <Footer></Footer>}
      </div>
    </>
  );
}
