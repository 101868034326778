import { Box, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import { Grid } from "@mui/material";

export default function Footer() {
  const iconStyle = {
    fontSize: "2em",
  };

  return (
    <Box
      sx={{
        borderTop: "solid 2px lightgrey",
        margin: "auto",
        width: "75%",
        height: "350px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        bottom: 0,
      }}
    >
      <Typography variant="h5" sx={{ mb: 1 }}>
        <AutoAwesomeIcon></AutoAwesomeIcon>
      </Typography>
      <Typography variant="h5" sx={{ mb: 4 }}>
        What's next?
      </Typography>
      <Typography sx={{ mb: 4 }}>
        Grab a copy of my&nbsp;
        <a
          className="hover-a"
          href="/static/Resume_JohannineEnerio"
          download
        >
          resume
        </a>
        &nbsp;or&nbsp;
        <a
          className="hover-a"
          href="https://www.linkedin.com/in/johannine-enerio/"
        >
          connect and say hello
        </a>
        !
      </Typography>
      <Box>
        <Grid container spacing={3}>
          <Grid item>
            <a className="hover-a" title="LinkedIn" href="https://www.linkedin.com/in/johannine-enerio/">
              <LinkedInIcon style={iconStyle}/>
            </a>
          </Grid>
          <Grid item>
            <a className="hover-a" title="Copy my email" href="mailto:johannine.enerio@gmail.com">
              <MailIcon style={iconStyle}/>
            </a>
          </Grid>
          <Grid item className="hover-a">
            <a className="hover-a" title="GitHub" href="https://github.com/johan9se">
              <GitHubIcon style={iconStyle}/>
            </a>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
