import { Box } from "@mui/material";
// import { TodoCreateForm } from "../ui-components";
import { DataStore } from '@aws-amplify/datastore';
import { Todo } from "../models";
import { useEffect, useState } from "react";

// import { API, graphqlOperation } from 'aws-amplify'
// import { createTodo } from '../graphql/mutations'
import { StorageManager } from '@aws-amplify/ui-react-storage';
import PorfolioContentCreateForm from "../ui-components/PorfolioContentCreateForm";

// import { listTodos } from '../graphql/queries'

// const initialState = { name: '', description: '' }


export default function Form() {
  // const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])

  // function setInput(key, value) {
  //   setFormState({ ...formState, [key]: value })
  // }

  async function fetchTodos() {
    try {
      // const todoData = await API.graphql(graphqlOperation(listTodos))
      // const todos = todoData.data.listTodos.items
      const getData = async () => {
        const tds = await DataStore.query(Todo)
        setTodos(tds)
      }
      getData()
    } catch (err) { console.log('error fetching todos') }
  }

  // async function addTodo(fields) {
  //   try {
  //     console.log(fields)
  //     if (!formState.name || !formState.description) return
  //     const todo = { ...formState }
  //     setTodos([...todos, todo])
  //     setFormState(initialState)
  //     await API.graphql(graphqlOperation(createTodo, {input: todo}))
  //     fetchTodos()
  //   } catch (err) {
  //     console.log('error creating todo:', err)
  //   }
  // }

  useEffect(() => {
    fetchTodos()
  }, [])

  return (
    <Box sx={{
      minWidth: "70vw",
      maxWidth: "85vw",
      height: "80vh",
      pt: "85px",
      mx: "auto",
    }}>
      <PorfolioContentCreateForm></PorfolioContentCreateForm>
      {/* <TodoCreateForm onSuccess={addTodo}/> */}
      {/* <TodoCreateForm onSubmit={fields => console.log(fields)} /> */}
      {/* <TodoUpdateForm></TodoUpdateForm> */}
      <StorageManager
        accessLevel="public"
        acceptedFileTypes={['image/*']}
        maxFileCount={10}
      />
      <Box>ToDo's:</Box>
      {todos.forEach((todo, index) => {
        <div key={index}>hello {todo}!</div>
      })}
    </Box>
  );
}