export const assetList = [
  {
    section: "Dev",
    image: ["/images/Projects/W_captvre.jpeg", "/images/Projects/W_captvre1.png", "/images/Projects/W_captvre2.png", "/images/Projects/W_captvre3.png", "/images/Projects/W_captvre4.png", "/images/Projects/W_captvre5.jpeg"],
    title: "CAPTVRE",
    summary: "Bringing Photography into VR (1st Prize for 22nd STePS)",
    desc: `Academic Project - NUS CS4240 Y4S2 (1st Prize for 22nd STePS)<br><br>
        CaptVRe delivers a true-to-life photography experience for beginners to learn how to use a camera and for professionals to hone their craft in a VR environment.<br><br>
        Click <a target="_blank" href="https://www.youtube.com/watch?v=_62BHVohn84">here </a> to watch our showcase video.<br><br>
        Links: <a target="_blank" href="https://uvents.nus.edu.sg/event/22nd-steps/module/CS4240/project/9">Project Details</a>`,
    tags: ["Unity", "VR Development", "C#", "Animation"],
  },
  {
    section: "Dev",
    image: ["/images/Projects/W_3242anim.jpg", "/images/Projects/W_3242anim1.png", "/images/Projects/W_3242anim2.png"],
    title: "Death`s Embrace",
    summary: "A Short Animation about a cat and Death",
    desc: `Academic Project - NUS CS3242 Y4S2 <br><br> 
        This is a short animation project for CS3242 3D Modelling and Animation. It was produced using Unity, Blender and iMovie. <br><br>
        It tells the story of a cat who is more friendly with Death than Life. It follows the journey of an injured cat kicked out from an abusive home, and is unable to run and play normally like other stray cats, which are all taken care of by Life. As the days pass by and the cat gets weaker, Death appears and offers the cat to take it away. In the end, the cat dreams about having a lovely new family and rejects Death. However, Death stays by its side, and cares for the cat and embraces it, showing that it will always be there to help.<br><br>
        Contribution: Cat Character Animation, Sound Engineering, Team Leadership <br><br> 
        Click <a target="_blank" href="https://www.youtube.com/watch?v=TFdyu3FCABU">here </a> to watch the full film`,
    tags: ["Unity", "Blender", "3D Modelling", "Animation"],
  },
  {
    section: "Dev",
    image: ["/images/Projects/W_woestyn.png", "/images/Projects/W_woestyn1.png", "/images/Projects/W_woestyn2.png", "/images/Projects/W_woestyn3.png"],
    title: "The Wild Woestyn",
    summary: "A 3D game where you comandeer a spaceship on a desert planet",
    desc: `NUS CS4240 Y4S2 - Assignment 1<br><br> 
        Woestyn (meaning "desert" in Afrikaans) is a planet that you can explore on your spaceship. You must collect enough energy capsules hidden around the planet, so that you can supply enough power to jet yourself off the surface and escape to freedom.<br><br>
        This was a basic 3D game made with Unity and some free assets from the Unity Asset Store. It was an interesting first experience at making a 3D game even though the game concept is really simple.<br><br>
        Links: <a target="_blank" href="https://uvents.nus.edu.sg/event/22nd-steps/module/CS4240/project/9">Project Details</a>`,
    tags: ["Unity", "VR Development", "C#"],
  },
  {
    section: "Dev",
    image: ["/images/Projects/W_peerprep.png", "/images/Projects/W_peerprep1.png", "/images/Projects/W_peerprep2.png"],
    title: "PeerPrep",
    summary: "A collaborative coding space for you to practice for technical interviews with your peers!",
    desc: `Academic Project - NUS CS319 Y4S1 <br><br> 
        PeerPrep is a web application is built to aid students in preparing themselves better for technical interviews through a peer matching system that allows them to collaborate on Leetcode style coding questions with another random user who is online on the system. They can then practice whiteboard-style interview questions together. <br><br> 
        Contribution: Frontend Development <br><br> 
        Links: <a target="_blank" href="https://github.com/CS3219-AY2223S1/cs3219-project-ay2223s1-g36">GitHub Repository</a>`,
    tags: ["React.js", "UI Design", "Node.js", "MongoDB", "MERN"],
  },
  {
    section: "Dev|UX|Design",
    image: ["/images/Projects/W_omatian.png", "/images/Projects/W_omatian1.png", "/images/Projects/W_omatian2.png"],
    title: "Omatian SG",
    summary: "An online store I developed for my small art shop",
    desc: `Personal Project - Online Art Shop<br><br> 
        Omatian is a hobby-turned-small-business that I started before entering university. Over the years I have used it as a platform for practicing my web development skills by improving the shop interface, as well as a creative outlet to make art and stickers inspired by everyday life.<br><br> 
        Links: <a target="_blank" href="https://omatian.sg">Go To Shop</a>`,
        tags: ["PHP", "CSS3", "HTML5", "JS", "MySQL"],
  },
  {
    section: "Dev",
    image: ["/images/Projects/W_cg4_1.png", "/images/Projects/W_cg4_2.png", "/images/Projects/W_cg4_3.png", "/images/Projects/W_cg4_4.png"],
    title: "Computer Graphics Assignment",
    summary: "Implementing Progressive Refinement radiosity algorithm",
    desc: `NUS CS4247 Y4S2 - Assignment 4<br><br> 
        In this assignment we were tasked to complete the solution to implement progressive refinement first on a sample Cornell box scene, and then on a custom scene that we could design ourselves.<br><br>
        In this implementation, we had to use the Hemicube algorithm to compute delta form factors for each pixel on the hemicube and perform item buffering to identify the patch occupying a particular pixel. Then to perform progressive refinement, we had to iteratively update radiosity values of the gatherer patches based on the light power shot from shooter patches until the final global illumination solution was reached.<br><br>`,
    tags: ["OpenGL", "C++", "Computer Graphics"],
  },
  {
    section: "Dev",
    image: ["/images/Projects/W_spyxpenguin.png"],
    title: "SpyXPenguin",
    summary: "A simple hand-tracking game my friends and I made for NUS Hack&Roll 2023 (and placed in the Top 8!)",
    desc: `Hackathon Project - NUS Hack&Roll 2023 (Won Top 8) <br><br>
        This game attempts to improve the fine motor skills of both children and the elderly. Our primary target audience are “iPad kids” who have gotten so used to swiping and other gestures are missing out on fine motor skills. Fine motor skills are required for handwriting, which is why it is a problem when these kids go to school. Through our interactive game, they will use their hand gestures to navigate through the story while honing their fine motor skills. Similarly, the elderly would benefit from this as refining fine motor skills would mean that they can be independent. <br><br>
        Links:<br> <a target="_blank" href="https://devpost.com/software/spy-x-penguin">DevPost</a><br>
        <a target="_blank" href="https://github.com/HacknRoll2023/SpyXPenguin">GitHub Repository</a><br>
        <a target="_blank" href="https://hacknroll2023.github.io/SpyXPenguin/">Try the game</a>`,
    tags: ["Three.js", "React.js", "Handtracking.js", "Web Dev", "Game Dev"],
  },
  {
    section: "Dev",
    image: ["/images/Projects/W_lifeasier.png", "/images/Projects/W_lifeasier1.png", "/images/Projects/W_lifeasier2.png", "/images/Projects/W_lifeasier3.png"],
    title: "LifEasier",
    summary: "A Command Line Interface (CLI) application to help students manage classes, social events, deadlines and school notes!",
    desc: `Academic Project - NUS CS2113 Y2S1 <br><br>
    LifEasier helps Computer Engineering (CEG) students to manage their classes, social events, deadlines and school notes through the Command Line Interface (CLI). The CLI allows LifEasier to deliver this functionality through a lightweight and easy to use interface. If you can type fast, LifEasier can get your timetabling needs done faster than a traditional GUI app.<br><br>
        Links:<br> <a target="_blank" href="https://ay2021s1-cs2113t-w13-4.github.io/tp/">GitHub Pages (User & Developer Guides)</a><br>
        <a target="_blank" href="https://github.com/AY2021S1-CS2113T-W13-4/tp">GitHub Repository</a><br>
        <a target="_blank" href="https://docs.google.com/presentation/d/1hmZE1vEEwNjbPVY5YASKa6HNTFS7uqNM9IGRwPSFAXw/edit?usp=sharing">Project Pitch</a>`,
    tags: ["Three.js", "React.js", "Handtracking.js", "Web Dev", "Game Dev"],
  },
  // {
  //   section: "Dev",
  //   image: ["/images/Projects/W_hackathons.jpg", "/images/Projects/W_panicnomore1.png", "/images/Projects/W_panicnomore2.png"],
  //   title: "Panic No More!",
  //   desc: `Hackathon Project - MindfulHacks 2021 <br><br> 
  //   An online platform with gamified learning scenarios to empower individuals to take responsibility for their own mental health and wellness and teach them to practice mindfulness, while debunking common common myths about mental wellness! The fires represent sources of anxiety or depression. While we may be tempted to ignore our problems when distressed, or to make rash decisions, the easiest solution may not always be the best solution, and may even result in your "fires" growing bigger.<br><br>
  //   Links:<br> <a target="_blank" href="https://devpost.com/software/panic-no-more">DevPost</a><br>
  //   <a target="_blank" href="https://github.com/MindfulHacks21/PanicNoMore">GitHub Repository</a><br>
  //   <a target="_blank" href="https://youtu.be/XL0rR1YGqCk">Project Pitch Video</a><br>
  //   <a target="_blank" href="https://panic-no-more.herokuapp.com/index.html">Try it out</a>`,
  //   tags: ["Bootstrap", "CSS3", "HTML5", "JS", "PixiJS"],
  // },
  {
    section: "Dev",
    image: ["/images/Projects/W_panicnomore2.png", "/images/Projects/W_panicnomore1.png"],
    title: "Panic No More!",
    summary: "Hackathon Project - MindfulHacks 2021",
    desc: `Hackathon Project - MindfulHacks 2021 <br><br> 
    An online platform with gamified learning scenarios to empower individuals to take responsibility for their own mental health and wellness and teach them to practice mindfulness, while debunking common common myths about mental wellness! The fires represent sources of anxiety or depression. While we may be tempted to ignore our problems when distressed, or to make rash decisions, the easiest solution may not always be the best solution, and may even result in your "fires" growing bigger.<br><br>
    Links:<br> <a target="_blank" href="https://devpost.com/software/panic-no-more">DevPost</a><br>
    <a target="_blank" href="https://github.com/MindfulHacks21/PanicNoMore">GitHub Repository</a><br>
    <a target="_blank" href="https://youtu.be/XL0rR1YGqCk">Project Pitch Video</a><br>
    <a target="_blank" href="https://panic-no-more.herokuapp.com/index.html">Try it out</a>`,
    tags: ["Bootstrap", "CSS3", "HTML5", "JS", "PixiJS"],
  },
  {
    section: "Dev",
    image: ["/images/Projects/W_harvestbattle2.png"],
    title: "Harvest Battle",
    summary: "Hackathon Project - NUS Hack&Roll 2022",
    desc: `Hackathon Project - NUS Hack&Roll 2022 <br><br> 
    A strategic online multiplayer game where you and your friend compete to harvest the most amount of crops using a shared plot of land. Carefully plan where to grow your crops and take out your opponent with a few smartly placed sabotages. Inspired by the strict gathering pax limit implemented during COVID times where 3 or more was considered a crowd, we intend to create a multiplayer game that allows people to have fun competing with one another even if they are not allowed to meet physically.
    Links:<br> <a target="_blank" href="https://devpost.com/software/harvest-battle">DevPost</a><br>
    <a target="_blank" href="https://github.com/HacknRoll2022/HarvestBattle">GitHub Repository</a><br>
    <a target="_blank" href="https://hacknroll2022.github.io/HarvestBattle/">Try the game</a>`,
    tags: ["Bootstrap", "CSS3", "HTML5", "JS", "JQuery"],
  },
  {
    section: "Dev",
    image: ["/images/Projects/W_hangman1.png"],
    title: "Hangman Game",
    summary: "Front-End Development Group Project - TikTok Youth Camp 2022",
    desc: `Front-End Development Workshop Group Project - TikTok Youth Camp 2022 <br><br> 
    As part of a front-end development workshop organised by TikTok, this hangman game was built with React by a mixed group of university students with varying levels of development expereince. <br><br> 
    Links:<br> <a target="_blank" href="https://github.com/tiktok-youths-16/tiktokcamp.git">GitHub Repository</a><br>
    <a target="_blank" href="https://tiktokcamp-hangman.vercel.app/">Try the game</a>`,
    tags: ["React.js", "HTML5", "CSS3", "JS"],
  },
  // {
  //     section: `Dev`,
  //     image: [`/images/Projects/W_capstone3.jpg`, `/images/Projects/W_capstone1.png`],
  //     title: `Capstone`,
  //     desc: ``,
  //     tags: [`sample`, `tags`, `only`]
  // },
  {
    section: "UX",
    image: ["/images/Projects/W_tripez1.png", "/images/Projects/W_tripez2.png"],
    title: "TripEZ",
    summary: "An Interaction Design project for a Travel Planner app",
    desc: `Academic Group Project - NUS CS3240 (Interaction Design) Y4S1 <br><br> 
        TripEZ is a mobile application prototype built with the aim of helping travelers streamline the planning process by providing an intuitive platform that allows users to easily choose their travel locations, collect and organising travel information, and come up with an itinerary that suits the individual/group travelling style. The end design was derived through user research, contextual inquiry, and multiple rounds of wireframing and prototyping.<br><br> 
        Links: <a target="_blank" href="https://cs3240g4.wixsite.com/tripez">Project Portfolio</a>`,
    tags: ["UX", "Figma", "Prototyping", "Interaction Design"],
  },
  {
    section: "UX",
    image: ["/images/Projects/W_daze.png", "/images/Projects/W_daze2.png"],
    title: "Daze",
    summary: "An Interaction Design project for an app to promote better sleep",
    desc: `Academic Individual Project - NUS CS3240 (Interaction Design) Y4S1 <br><br> 
        Daze is a mobile application prototype design for a sleep aid application that can give users an immersive, and personalized sleeping experience. It is a multimodal application designed to be used to control a home equipped with smart furniture to monitor and enhance their sleep habit and quality.<br><br> 
        Links:<br><a target="_blank" href="https://docs.google.com/presentation/d/13WfNycSUTdSNjPP63RD5cm48Cdx1H3MkSvmOdtprugI/edit?usp=sharing">Project Portfolio Deck</a><br>
        <a target="_blank" href="https://www.figma.com/file/KzQwOMfqWdXc0Ag5vf558E/DesignExercise_JohannineEnerio?type=design&node-id=0-1">Figma Prototype</a>`,
    tags: ["UX", "Figma", "Prototyping", "Interaction Design"],
  },
  {
    section: "Design",
    image: ["/images/Graphics/G1.png"],
    title: "Barangay NUS Merch",
    summary: "Promotional posters for Barangay NUS merch in 2023",
    desc: "Promotional posters for Barangay NUS merch in 2023",
  },
  {
    section: "Design",
    image: ["/images/Graphics/G2.PNG", "/images/Graphics/G3.PNG"],
    title: "Barangay NUS Merch",
    summary: "Product designs I created for Barangay NUS merch",
    desc: "For this merch line, I helped to design the t-shirt, tote bag and lanyard. These products sport a cool simple monochrome design featuring significant landmarks and icons of the Philippines! 🇵🇭 [1-3 on T-Shirt, 4-6 on Tote] <br> 1. Banaue Rice Terraces 🌾 <br> 2. A Whaleshark and bangka (boat)⛵️ <br> 3. Mount Mayon 🌋 and the Cagsawa Church tower <br> 4. Jeepney 🚍<br> 5. Boodle fight 🍗 <br> 6. Boracay beach 🏖",
  },
  {
    section: "Design",
    image: ["/images/Graphics/G4.png"],
    title: "Barangay NUS ISD Event Poster",
    desc: "This poster was designed for International Students Day 2022",
  },
  {
    section: "Design",
    image: ["/images/Graphics/G6.png"],
    title: "Barangay NUS mOSAic 2022 Poster",
    desc: `This poster was designed for the 2022 mOSAic festival. <br><br>
        Photo credits: <a href="https://nus.edu.sg/osa/student-life/community-engagement/events">NUS Office of Student Affairs</a>`,
  },
  {
    section: "Design",
    image: ["/images/Graphics/G7.png", "/images/Graphics/G8.png"],
    title: "Barangay NUS Merch",
    summary: "Promotional posters for Barangay NUS merch in 2022",
    desc: "Promotional posters for Barangay NUS merch in 2022",
    tags: [],
  },
  {
    section: "Design",
    image: ["/images/Graphics/G9.png"],
    title: "AMD Production Team T-Shirt",
    desc: "A shirt I volunteered to design for the company`s production (photography/videography) team during my internship there.",
    tags: [],
  },
  {
    section: "Design",
    image: ["/images/Graphics/G10.png"],
    title: "AMD STEM Lab Program Hoodie",
    desc: "A hoodie I was tasked to design for the company`s STEM Lab Outreach program during my internship there.",
    tags: [],
  },
  {
    section: "Design",
    image: ["/images/Art/A7.jpg", "/images/Art/A5.jpg", "/images/Art/A4.jpg", "/images/Art/A3.jpg", "/images/Art/A2.jpg", "/images/Art/A1.jpg"],
    title: "Omatian Designs",
    desc: `Some of my favourite designs that I've made for my small art hobby-turned-business, Omatian! <br><br> 
    The expression “omatian!” is kind of like a pet phrase, something to the effect of OMG, except with a dash of Singapore's flavour. See, Omatian is mainly inspired by everyday life, right here in Singapore. You're going to see a lot of designs that are based off of local landmarks or foodie favourites because I wanted to create something familiar that people can easily connect with.<br><br> 
    Check out the <a target="_blank" href="https:omatian.sg">website</a> I made for my shop!`,
  },
  {
    section: "Design",
    image: ["/images/Art/A6.jpeg"],
    title: "Omatian Stickers",
    desc: `My very own billboard- I mean... personal collection of stickers on my laptop. <br><br> 
    Check out the <a target="_blank" href="https:omatian.sg">website</a> I made for my shop!`,
  },
  {
    section: "Design",
    image: ["/images/Art/A8.jpg"],
    title: "Sticker Set Commission Piece",
    desc: "Commissioned Sticker set for a group of friends",
  },
  {
    section: "Design",
    image: ["/images/Art/A11.png", "/images/Art/A10.png", "/images/Art/A9.png"],
    title: "Commissioned Pieces",
    summary: "for Singapore Sustainable Gallery",
    desc: "Commissioned social media pieces for Singapore Sustainable Gallery to promote sustainable shopping especially during sale seasons like 11.11.",
  },
  {
    section: "Design",
    image: ["/images/Art/A12_2.png"],
    title: "Christmas Card Design",
    summary: "Christmas Card Design Contest | Brother Singapore",
    desc: `<a target="_blank" href="https://www.brother.com.sg/en/contents/xmas-card-contest">Winning piece</a> in Singapore region`,
  },
  {
    section: "Design",
    image: ["/images/Art/A14.png", "/images/Art/A13.png", "/images/Art/A15.png", "/images/Art/A16.png", "/images/Art/A17.png", "/images/Art/A18.jpg", "/images/Art/A19.JPG", "/images/Art/A20.JPG"],
    title: "Commissioned Pieces",
    summary: "for @tutu_and_ball",
    desc: "I was commissioned by Jazmine (@tutu_and_ball on Instagram) to design some on-brand postcards for her to include with her orders back in 2020. Each whimsical illustration features a tutu (or pacifier) and a ball, as per her brand name!",
  },
];
